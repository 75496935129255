import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { appStore, googlePlay } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mt="13px" column center>
          <CFView h3 futura bold color="#C60000" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column center white bold>
            <CFView h4 mb="15px" textCenter maxWidth="75vw">
              Meadow SushiRestaurant is now taking online orders. Order through
              our website or download our app in your app store! Collect points
              and redeem fantastic rewards!
            </CFView>
            <CFView row center>
              <CFView mr="10px">
                <CFLink href="https://apps.apple.com/us/app/meadow-sushi/id1480802754">
                  <CFImage
                    h="40px"
                    src={appStore}
                    alt="Meadow SushiRestaurant App Store"
                  />
                </CFLink>
              </CFView>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.meadowsushi">
                <CFImage
                  h="40px"
                  src={googlePlay}
                  alt="Meadow SushiRestaurant Google Play"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mr="25px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="20px" minWidth="250px">
            DOWNLOAD APP
          </CFView>
          <CFView column justifyStart white bold>
            <CFView maxWidth="260px" fontSize="14px" mb="15px">
              Meadow SushiRestaurant is now taking online orders. Order through
              our website or download our app in your app store! Collect points
              and redeem fantastic rewards!
            </CFView>
            <CFLink href="https://apps.apple.com/us/app/meadow-sushi/id1480802754">
              <CFImage
                h="37px"
                mt="5px"
                src={appStore}
                alt="Meadow SushiRestaurant App Store"
                hover
              />
            </CFLink>
            <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.meadowsushi">
              <CFImage
                h="37px"
                mt="5px"
                src={googlePlay}
                alt="Meadow SushiRestaurant Google Play"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
